import React from "react"
import Layout from "../components/layout"

const PhotographyPage = () => (

    <Layout>
        <h1>Photography</h1>
        <p>Coming Soon</p>
    </Layout>

)

export default PhotographyPage;